var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "card",
                { attrs: { title: "Portfolio Generator" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-file-input", {
                        attrs: {
                          label: "Data file",
                          filled: "",
                          "prepend-icon": "fa-file-excel",
                        },
                        model: {
                          value: _vm.dataFile,
                          callback: function ($$v) {
                            _vm.dataFile = $$v
                          },
                          expression: "dataFile",
                        },
                      }),
                      _c("h3", [_vm._v("Lignes à analyser")]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex mb-4",
                          staticStyle: { gap: "1.5em" },
                        },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Ligne indirecte",
                              value: "fund",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              label: "Ligne directe",
                              value: "direct",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              label: "Ligne mezzanine",
                              value: "mezzanine",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("upload-error", { attrs: { error: _vm.error } }),
                      _vm.uploadingData && _vm.uploadDataPercentage < 100
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("v-progress-circular", {
                                attrs: { value: _vm.uploadDataPercentage },
                              }),
                              _vm._v(" Uploading .. "),
                            ],
                            1
                          )
                        : _vm.uploadingData
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                              _vm._v(" Parsing data .. "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.type.length === 0 ||
                              !_vm.dataFile ||
                              _vm.uploadingData,
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.uploadData()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", dark: "" } }, [
                            _vm._v(" mdi-upload "),
                          ]),
                          _vm._v(" Generate "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }